body {
  margin: 0;
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


hr {
  margin-top: 10rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(16, 112, 104, 0.952);
}

.centerElement {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.mainPageCard {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  max-width: 600px;
}


.checkedInDetailsCard {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  max-width: 600px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.blinkText {
  animation: blink 0.75s infinite;
}
