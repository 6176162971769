.singleCheckInRow {
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgb(155, 250, 253);
  transition: box-shadow .2s ease-in-out;
  cursor: pointer;
  transition: transform 0.3s;
  /* transition: background-color 0.3s, color 0.3s; */
}

.singleCheckInRow:hover {
  transform: scale(1.01);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: rgb(70, 248, 5); 
} 

.checkInRowUserName {
  font-weight: bold;
  font-size: larger;
}